import React from "react";

class Spinner extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isShowing:props.isShowing,
        }
    }

    render(){
    
        return(
        <div className={this.state.isShowing ? this.props.size:"hidden"}>

        </div>)
    }
}
export default Spinner;